/* eslint-disable max-len */
const ImageLogoDark = () => (
  <svg id="test_logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 783.58 238.66">
    <path
      style={{ fill: '#1f2549' }}
      d="M90.3,83.92l6.24,29.56v2.31a79.82,79.82,0,0,0-34.88-7.62q-32.56,0-32.56,28.18,0,13.4,7.85,21.48t24.94,8.08a87.35,87.35,0,0,0,36.26-7.39v2.31l-6.23,29.56q-13.4,7.4-35.8,7.39-27.94,0-42-16.17T0,136.81q0-28.64,14-44.57T55.66,76.3Q76,76.3,90.3,83.92Z"
    />
    <path
      style={{ fill: '#1f2549' }}
      d="M188,183.69q-14.78,14.09-38.34,14.09-21.24,0-32.21-9.24t-11-26.56q0-32.79,53.11-32.79h25.64v-5.55a16.22,16.22,0,0,0-2-8.08,14.59,14.59,0,0,0-4.62-5.19,20.8,20.8,0,0,0-7.16-2.66,60.54,60.54,0,0,0-8-1.16q-3.47-.22-8.31-.23-13.86,0-39.5,5.32v-2.31l6.47-27.26a113.67,113.67,0,0,1,37-5.77q25.17,0,40.88,11t15.7,32.45v34.87a112.87,112.87,0,0,0,6.93,38.11V195H194.46A56.72,56.72,0,0,1,188,183.69Zm-19.05-15.47a25.81,25.81,0,0,0,10.62-5,12.06,12.06,0,0,0,5-10v-4.85H158.2q-10.86,0-16,2.31a8.16,8.16,0,0,0-5.2,8.08q0,5.77,4.85,8.2t15.47,2.42A58.18,58.18,0,0,0,168.94,168.22Z"
    />
    <path
      style={{ fill: '#1f2549' }}
      d="M271.13,92q21.72-15.7,37-15.71,21.24,0,31.4,16.17Q364.44,76.3,382,76.3q37.65,0,37.64,50.12v27.71a135.63,135.63,0,0,0,6.24,40.42V195H382.22v-.46a143.53,143.53,0,0,0,6.24-40.42v-24q0-11.09-4.16-16.4t-13.63-5.31q-11.31,0-23.78,8.32c.3,4,.46,7.08.46,9.23v28.18a135.94,135.94,0,0,0,6.23,40.42V195H309.93V192.7q6.24-21.7,6.24-38.57V130.57q0-22.17-17.78-22.17-11.55,0-24.25,8.55v36.72a128.58,128.58,0,0,0,6.23,39V195H237.19V192.7a115.42,115.42,0,0,0,6.23-38.8V132.65q0-27.95-10.62-43.65V86.69a64.07,64.07,0,0,0,33-10.39h2.54Z"
    />
    <path
      style={{ fill: '#1f2549' }}
      d="M545.16,92.58q13.05,16.29,13.05,44.46T545.27,181.5q-12.93,16.27-34.41,16.28-20.55,0-33.95-13.86v11.32q0,26.32,6.24,41.11v2.31H439.73v-2.31q6.47-17.32,6.47-39.49V126q0-15-6.93-35.79V87.85q6.93-.93,10.62-1.62A54.91,54.91,0,0,0,459.82,83a79,79,0,0,0,12.71-6.7h2.54V93.39a42.51,42.51,0,0,1,15.58-12.47,45.48,45.48,0,0,1,20-4.62Q532.11,76.3,545.16,92.58Zm-68.25,66.17q9.7,7.16,24.48,7.16,13.17,0,19.63-7.74T527.49,137q0-13.17-6.58-20.9t-20-7.74q-14.08,0-24,6.47Z"
    />
    <path
      style={{ fill: '#1f2549' }}
      d="M610.4,153.9a148,148,0,0,0,6.24,38.8V195H573.45V192.7q6.24-18.48,6.24-41.11V132.65q0-27.95-6.93-43.19v-2.3a68.49,68.49,0,0,0,35.1-10.86h2.54Z"
    />
    <path
      style={{ fill: '#1f2549' }}
      d="M671.14,153.67a128.29,128.29,0,0,0,6.24,39V195H634.19V192.7a115.17,115.17,0,0,0,6.23-38.8V132.65q0-27.95-10.62-43.65V86.69a64.08,64.08,0,0,0,33-10.39h2.54L669.06,98q9.93-21.7,30-21.71,9.46,0,15.47,2.54l6.23,30.26v2.3a211.53,211.53,0,0,0-21.94-1.15q-11.77,0-19.74,6.7t-8,19.17Z"
    />
    <path
      style={{ fill: '#1f2549' }}
      d="M765.14,153.9a148.39,148.39,0,0,0,6.23,38.8V195H728.19V192.7q6.22-18.48,6.23-41.11V132.65q0-27.95-6.93-43.19v-2.3A68.56,68.56,0,0,0,762.6,76.3h2.54Z"
    />
    <polygon
      style={{ fill: '#119383' }}
      points="576.37 11.51 568.31 40.14 587.24 59.07 613.75 55.29 619.43 28.78 599.83 11.51 576.37 11.51"
    />
    <polygon
      style={{ fill: '#119383' }}
      points="713.44 22.29 731.22 54 763.95 54 783.58 27.82 765.58 0 733.72 2.02 713.44 22.29"
    />
  </svg>
);

export default ImageLogoDark;
