/* eslint-disable max-len */
const ImageLogoBrightMobile = () => (
  <svg id="test_logo" width="106" height="32" viewBox="0 0 106 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_27_97)">
      <path
        d="M12.1337 11.2521L12.9722 15.2156V15.5253C11.5072 14.836 9.90486 14.4867 8.28531 14.5036C5.36856 14.5036 3.91019 15.7631 3.91019 18.2821C3.91019 19.4799 4.2618 20.4399 4.965 21.1621C5.66821 21.8844 6.78528 22.2455 8.31621 22.2455C9.99259 22.2662 11.654 21.9284 13.1885 21.2547V21.5644L12.3514 25.5278C11.151 26.1893 9.5475 26.5196 7.54089 26.5187C5.03801 26.5187 3.15682 25.796 1.89732 24.3506C0.637814 22.9052 0.00537483 20.9029 0 18.3437C0 15.7837 0.627064 13.7917 1.88119 12.3677C3.13532 10.9438 5.00128 10.2313 7.47908 10.2304C9.30115 10.2304 10.8527 10.571 12.1337 11.2521Z"
        fill="white"
      />
      <path
        d="M25.2618 24.6295C23.9378 25.889 22.2205 26.5187 20.11 26.5187C18.2073 26.5187 16.7646 26.1058 15.7819 25.2798C14.7992 24.4539 14.3065 23.2668 14.3038 21.7186C14.3038 18.7876 16.6826 17.3221 21.4403 17.3221H24.8855V16.5779C24.8919 16.1996 24.7993 15.8262 24.6168 15.4945C24.469 15.2161 24.2561 14.9773 23.996 14.7986C23.7001 14.6215 23.3739 14.5006 23.0339 14.442C22.6795 14.3663 22.3203 14.3143 21.9589 14.2864C21.6481 14.2668 21.2759 14.2565 20.8423 14.2556C19.6007 14.2556 17.8315 14.4934 15.5347 14.9689V14.6592L16.4041 11.0041C18.007 10.4737 19.6871 10.2123 21.3758 10.2305C23.6305 10.2305 25.4615 10.7221 26.8689 11.7054C28.2762 12.6886 28.9794 14.139 28.9785 16.0563V20.7318C28.9915 22.4759 29.3065 24.2046 29.9097 25.8416V26.146H26.1298C25.7831 25.6754 25.4918 25.1666 25.2618 24.6295V24.6295ZM22.702 22.5553C23.223 22.4441 23.7113 22.2147 24.129 21.8849C24.3412 21.7321 24.5131 21.5303 24.6301 21.2968C24.7471 21.0633 24.8057 20.8051 24.8009 20.5441V19.8938H21.2575C20.2847 19.8938 19.568 19.997 19.1076 20.2035C18.8912 20.2871 18.707 20.437 18.5815 20.6317C18.4559 20.8263 18.3955 21.0557 18.4089 21.2869C18.4089 21.8026 18.6261 22.1691 19.0606 22.3863C19.495 22.6035 20.1879 22.7117 21.1393 22.7108C21.6637 22.7114 22.1868 22.6592 22.7007 22.5553H22.702Z"
        fill="white"
      />
      <path
        d="M36.4319 12.3356C38.3776 10.9322 40.0349 10.23 41.4036 10.2291C43.3063 10.2291 44.7127 10.9518 45.6229 12.3972C47.8543 10.9527 49.7566 10.2305 51.3296 10.2305C54.7023 10.2305 56.3882 12.4705 56.3873 16.9507V20.6661C56.3907 22.5045 56.6734 24.3318 57.2258 26.0857V26.146H51.3592V26.0843C51.8965 24.327 52.1789 22.5019 52.1976 20.6647V17.4468C52.1976 16.4554 52.0113 15.7225 51.6387 15.2478C51.266 14.7732 50.6555 14.5358 49.8072 14.5358C48.794 14.5358 47.7289 14.9077 46.6119 15.6514C46.6522 16.1877 46.6737 16.6007 46.6737 16.889V20.6674C46.6772 22.5057 46.9595 24.3329 47.5108 26.087V26.146H41.6455V25.8376C42.2045 23.8979 42.484 22.174 42.484 20.6661V17.5071C42.484 15.5254 41.6876 14.5345 40.0949 14.5345C39.0602 14.5345 37.9741 14.9166 36.8364 15.6809V20.6044C36.8439 22.3801 37.1263 24.1439 37.6735 25.8336V26.146H31.8714V25.8376C32.4464 24.164 32.7295 22.4044 32.7085 20.6352V17.786C32.7085 15.2876 32.2328 13.3367 31.2815 11.9333V11.6236C32.8596 11.5737 34.3936 11.0918 35.7157 10.2305H36.057L36.4319 12.3356Z"
        fill="white"
      />
      <path
        d="M73.2536 12.4133C74.4226 13.8694 75.0072 15.8565 75.0072 18.3746C75.0072 20.8926 74.4276 22.8797 73.2684 24.3359C72.1101 25.7902 70.5689 26.5178 68.6447 26.5187C66.8038 26.5187 65.2832 25.8993 64.0828 24.6603V26.1782C64.0828 28.5308 64.3623 30.3682 64.9213 31.6903V32H59.0869V31.6903C59.6665 30.1421 59.9563 28.3771 59.9563 26.3954V16.8943C59.9563 15.5535 59.6459 13.9539 59.0251 12.0955V11.7791C59.6459 11.696 60.1216 11.6236 60.4521 11.5619C60.9097 11.4601 61.3564 11.3151 61.7864 11.1288C62.3819 10.8817 62.9535 10.581 63.4943 10.2304H63.8356V12.5219C64.3906 11.8067 65.1082 11.2336 65.9291 10.8499C66.7659 10.4418 67.6851 10.2299 68.6165 10.2304C70.5389 10.2304 72.0846 10.9581 73.2536 12.4133ZM64.0828 21.2855C64.9517 21.9255 66.0482 22.2455 67.3722 22.2455C68.552 22.2455 69.4312 21.8996 70.0099 21.2077C70.5886 20.5159 70.8784 19.5697 70.8793 18.3692C70.8793 17.192 70.5846 16.2579 69.9951 15.5669C69.4057 14.8759 68.5099 14.53 67.3077 14.5291C66.0464 14.5291 64.9714 14.8183 64.0828 15.3966V21.2855Z"
        fill="white"
      />
      <path
        d="M82.02 20.6352C82.0665 22.3988 82.3485 24.1484 82.8585 25.8376V26.146H77.055V25.8376C77.614 24.1857 77.8935 22.3483 77.8935 20.3255V17.786C77.8935 15.2876 77.5831 13.3572 76.9623 11.995V11.6866C78.638 11.6389 80.2688 11.1354 81.6787 10.2304H82.02V20.6352Z"
        fill="white"
      />
      <path
        d="M90.1817 20.6044C90.189 22.3802 90.4718 24.1442 91.0201 25.8336V26.146H85.2167V25.8376C85.7923 24.1641 86.0755 22.4044 86.0538 20.6352V17.786C86.0538 15.2876 85.5781 13.3367 84.6268 11.9333V11.6236C86.2049 11.5737 87.7389 11.0917 89.061 10.2305H89.4023L89.9022 13.14C90.7917 11.2003 92.1354 10.23 93.9333 10.2291C94.7807 10.2291 95.4736 10.3426 96.012 10.5697L96.8491 14.627V14.9354C95.8698 14.8331 94.8858 14.7816 93.901 14.7812C92.8467 14.7812 91.9625 15.0807 91.2486 15.6796C90.5346 16.2785 90.1763 17.1352 90.1736 18.2499L90.1817 20.6044Z"
        fill="white"
      />
      <path
        d="M102.813 20.6352C102.859 22.3987 103.141 24.1482 103.65 25.8376V26.146H97.8475V25.8376C98.4047 24.1857 98.6838 22.3483 98.6847 20.3255V17.786C98.6847 15.2876 98.3743 13.3572 97.7535 11.995V11.6866C99.4296 11.6387 101.061 11.1352 102.471 10.2304H102.813V20.6352Z"
        fill="white"
      />
      <path
        d="M77.4473 1.54327L76.3643 5.38204L78.908 7.92021L82.4701 7.41338L83.2334 3.85887L80.5997 1.54327H77.4473Z"
        fill="#119383"
      />
      <path
        d="M95.8655 2.98869L98.2546 7.24043H102.653L105.29 3.73016L102.872 0L98.5906 0.270846L95.8655 2.98869Z"
        fill="#119383"
      />
    </g>
    <defs>
      <clipPath id="clip0_27_97">
        <rect width="105.29" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default ImageLogoBrightMobile;
