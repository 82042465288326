import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { deviceMaxWidth } from '~/src/styles/breakpoints';
import { routes } from '~/src/utils/routing';
import LocalizedLink from '~/src/components/LocalizedLink';
import ImageLogoBright from './icons-and-images/ImageLogoBright';
import ImageLogoDark from './icons-and-images/ImageLogoDark';
import ImageLogoBrightMobile from './icons-and-images/ImageLogoBrightMobile';
import ImageLogoDarkMobile from './icons-and-images/ImageLogoDarkMobile';

interface Props {
  isDark: boolean;
}

export function HomepageLogoLink({ isDark = false }: Props) {
  return (
    <LocalizedLink href={routes.index} passHref={true}>
      <a href="#" className="logo">
        <ImageWrapper>{isDark ? <ImageLogoDark /> : <ImageLogoBright />}</ImageWrapper>

        <ImageWrapperMobile>{isDark ? <ImageLogoDarkMobile /> : <ImageLogoBrightMobile />}</ImageWrapperMobile>
      </a>
    </LocalizedLink>
  );
}

const ImageWrapper = styled.div`
  height: 40px;
  cursor: pointer;
  width: 130px;

  @media ${deviceMaxWidth.phone} {
    display: none;
  }
`;

const ImageWrapperMobile = styled.div`
  display: none;

  @media ${deviceMaxWidth.phone} {
    height: 40px;
    cursor: pointer;
    width: 130px;
    height: 27px;
    display: block;
  }
`;

const mapStateToProps = state => ({});

export default connect(mapStateToProps, dispatch => ({ dispatch }))(HomepageLogoLink);
